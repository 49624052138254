<template>
  <div>
    <div v-if="access.type === 'forbidden' && $context.user._isPublishVacancies">
      <access-warning />
    </div>
    <div v-else>
      <fude-table
        :settings="settings"
        class="app-person-buyed dense"
        ref="table"
        @loadBegin="onLoadBegin"
        @loadEnd="onLoadEnd"
      >
        <span slot="ts" slot-scope="{ item }">
          {{ $t(new Date(parseInt(item.ts))) }}
        </span>
        <span slot="person_full_name" slot-scope="{ item }" >
          <a v-if="item.respond_id" :href="'../#/respond/' + item.respond_id" target="_blank">
            {{ item.person_full_name }}
          </a>
          <a v-else :href="'../#/resume/' + item.person_id" target="_blank">
            {{ item.person_full_name }}
          </a>
        </span>
        <v-form>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            lazy
            transition="scale-transition"
            offset-y
            class="d-inline-flex"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.tsFrom"
                label="Период с"
                readonly
                v-on="on"
                clearable
                class="d-inline-flex ma-1"
                style="width: 200px"
                :disabled="disablePeriod"
              />
            </template>
            <v-date-picker
              v-model="filter.tsFrom"
              @input="menu1 = false"
              locale="ru-ru"
            />
          </v-menu>

          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            lazy
            transition="scale-transition"
            offset-y
            class="d-inline-flex"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.tsBefore"
                label="Период по"
                readonly
                v-on="on"
                clearable
                class="d-inline-flex ma-1"
                style="width: 200px"
                :disabled="disablePeriod"
              />
            </template>
            <v-date-picker
              v-model="filter.tsBefore"
              @input="menu2 = false"
              locale="ru-ru"
            />
          </v-menu>
        </v-form>
      </fude-table>
    </div>
  </div>
</template>

<script>
import AccessWarning from '../access/access-warning.vue';

export default {
  name: 'app-person-buyed',
  
  components: {
		AccessWarning
	},

  data() {
    return {
      settings: {
        title: { text: 'Купленные резюме ({0})' },
        pagination: true,
        openFirst: false,
        split_text: true,

        search: (params) => {
          params.filter = {
            AND: []
          };

          if (this.filter.tsFrom) {
            params.filter.AND.push({
              name: 'ts',
              value: { '>=' : this.parseTs(this.filter.tsFrom) }
            });
          }

          if (this.filter.tsBefore) {
            params.filter.AND.push({
              name: 'ts',
              value: { '<=' : this.parseTs(this.filter.tsBefore) }
            });
          }

          return params;
        },

        headers: {
          user_full_name: { text: { text: 'Имя пользователя' }, width: 180 },
          user_name: { text: { text: 'Псевдоним' }, width: 180 },
          ts: { text: { text: 'Дата' }, width: 180, sortable: true, default: 'desc' },
          price: { text: { text: 'Стоимость' }, width: 180 },
          person_full_name: { text: { text: 'ФИО' }}
        },

        rest: '/api/v1/main/person_buyed/' + window.localStorage['organization'],

        onLoad: (items) => {
          items = items || [];
          items.forEach((item) => {
            if (!item.person_full_name) {
              item.person_full_name = 'ФИО не определено';
            }
            if (!item.user_id) {
              item.user_full_name = 'Компенсация от Job24';
            }
          });
        }
      },

      filter: {
        tsFrom: null,
        tsBefore: null
      },

      menu1: false,
      menu2: false,
      disablePeriod: false,
			access: {}
    }
  },

  watch: {
    'filter.tsFrom'() {
      this.$nextTick(() => this.$refs.table.reload());
    },
    'filter.tsBefore'() {
      this.$nextTick(() => this.$refs.table.reload());
    }
  },

  async mounted() {
    await this.loadAccess();
  },

  methods: {
    async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
    onLoadBegin() {
      this.disablePeriod = true;
    },
    onLoadEnd({ error, items }) {
      this.disablePeriod = false;
    },
    parseTs(value, def) {
      if (!value) {
        return def;
      }
      const [year, month, day] = value.split('-');
      return new Date(year, month - 1, day, 0, 0, 0 ,0).getTime();
    }
  }
}
</script>

<style lang="less">
.app-person-buyed {
  .v-toolbar__extension {
    height: 56px !important;
    padding-top: 8px;
  }
}
</style>