<template>
	<v-container class="simple-search">
		<v-layout row wrap>
			<v-flex offset-xs2 xs8 >
				<v-btn 
					class="v-btn--text-transform-none ml-0" 
					color="primary" 
					style="cursor: default" 
					:ripple="false" 
					depressed 
					:input-value="true"
				>
					Простой поиск
				</v-btn>
				<v-btn 
					class="v-btn--text-transform-none ml-0"
					outline
					color="primary"
					@click="$emit('update:simple', false)"
					v-if="this.$vuetify.breakpoint.name !== 'xs'"
				>
					Расширенный поиск
				</v-btn>
				<a @click="clear()"><u>Очистить</u></a>
			</v-flex>
		</v-layout>
		<v-layout row wrap v-if="searchForVacancy">
			<v-flex xs2 >
				<h3>Для вакансии: </h3>
			</v-flex>
			<v-flex xs8>
				<v-chip
					v-model="searchForVacancy"
					close
					label
					outline
					@click="openVacancy(vacancy)"
				>   
					<span style="cursor: pointer">
						{{ vacancy.name }}
					</span>
				</v-chip>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2 >
				<h3>Должность: </h3>
			</v-flex>
			<v-flex xs8>
				<v-text-field
					v-model="model.search_settings[0].text"
					solo
				/>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2 >
				<h3>Ключевое слово: </h3>
			</v-flex>
			<v-flex xs8 >
				<v-text-field
					solo
					v-model="model.search_settings[1].text"
					counter
					maxlength="119"
					placeholder="Максимум 120 символов"
				/>
				</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Регион: </h3>
			</v-flex>
			<v-flex xs8>
				<v-autocomplete 
					solo
					placeholder="Укажите регион"
					v-model="model.regions"
					:items="regions"
					:search-input.sync="searchRegions"
					:filter="customFilter"
					return-object
					multiple
					no-data-text="Начните вводить название региона"
					item-text="name"
					@change="searchRegions = null"
				>
					<template slot="selection" slot-scope="data">
						<v-chip 
							:selected="data.selected"
							close
							@input="removeRegion(model.regions, data.item)"
						>
							{{ data.item.name }}
						</v-chip>
					</template>
				</v-autocomplete>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import AppSalaryPanel from './salary-panel.vue';
import AppAgePanel from './age-panel.vue';
import AppBirthdayPanel from './birthday-panel.vue';
import AppSearchPanel from './search-panel.vue';

export default {
	name: 'app-simple-search',

	components: {
		AppAgePanel, 
		AppSalaryPanel, 
		AppBirthdayPanel, 
		AppSearchPanel
	},

	props: {
		simple: {
			type: Boolean
		},
		model: {
			type: Object
		},
		sexes: {
			type: Array
		},
		currencies: {
			type: Array
		}
	},

	data() {
		return {
			allRegions: [],
			searchRegions: null,
			vacancy: {},
			searchForVacancy: false
		}
	},

	computed: {
		regions() {
			if (this.model.regions && this.model.regions.length) this.allRegions.push(...this.model.regions);
			return this.sortGuide(this.allRegions);
		}
	},

	watch: {
		searchRegions() {
			if (this.isLoading) return;
			this.isLoading = true;

			fetch('/api/v1/main/guides/regions/short_query/' + (this.searchRegions && this.searchRegions.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query'))
				.then(res => res.json())
				.then(res => {
					this.allRegions = res.filter(i => i.type === 'hh');
					if (this.model.regions && this.model.regions.length) this.allRegions.push(...this.model.regions);
				})
				.catch(err => {
					console.log('err', err)
				})
				.finally(() => (this.isLoading = false));
		},
		searchForVacancy() {
			if (!this.searchForVacancy) {
				this.vacancy = {};
				window.localStorage.removeItem('vacancy_id');
				this.model.vacancy_id = null;
			}
		}
	},

	async mounted() {
		if (this.$context.user.isResearcher || this.$context.user.isAdmin) {
			if (this.model.vacancy_id) {
				this.vacancy = (await this.$http.get(`/api/v1/recruiting/vacancy/${this.model.vacancy_id}`)).data;
				this.searchForVacancy = true;
			}
		} else {
			this.searchForVacancy = false;
		}
	},

	methods: {
		clear() {
			this.model.search_settings = [
				{
					text: '',
					search_type: { id: '0efa102f-03ff-4a1d-aa94-348a258259e3', code: 'all_words', name: 'Все слова' },
          experience_period: { id: '5198423c-fe7a-490a-96b9-3201ac57a8a0', code: 'all', name: 'За все время опыта' },
					search_areas: [
						{ id: '4c8f283b-d89d-48cf-b50e-20a994f9ca02', code: 'resume', name: 'В названии резюме', parent_id: null },
						{ id: '430b96ee-2994-406b-80a4-7210e3d5490e', code: 'position', name: 'В должностях', parent_id: '8f1c7e7e-2222-475c-8fa0-4bfe435407e2' }
					]
				},
				{
					text: '',
					search_type: { id: '0efa102f-03ff-4a1d-aa94-348a258259e3', code: 'all_words', name: 'Все слова' },
          experience_period: { id: '5198423c-fe7a-490a-96b9-3201ac57a8a0', code: 'all', name: 'За все время опыта' },
					search_areas: [
						{ id: '05d73841-8f03-45f2-b862-63d0c9466fa2', code: 'tag', name: 'В ключевых навыках', parent_id: null },
						{ id: '7aad39ac-7d93-4a02-97b2-c045cc0715f6', code: 'company', name: 'В компаниях и отраслях', parent_id: '8f1c7e7e-2222-475c-8fa0-4bfe435407e2' },
						{ id: 'f5987478-f268-4adc-906e-0e19b631bfce', code: 'duty', name: 'В обязанностях', parent_id: '8f1c7e7e-2222-475c-8fa0-4bfe435407e2' },
						{ id: 'd1880894-d145-4c77-97df-cf187664e5a1', code: 'about', name: 'В обо мне', parent_id: null }
					]
				}
			];
			// this.model.birth_day = null;
			// this.model.birth_month = null;
			// this.model.birth_year = null;
			this.model.search_period = { id: '4892c204-611a-49de-88c3-4962dcd8fbb5', code: 'all_time', name: 'За весь период' };
			this.model.currency = {};
			this.model.move_type = { id: '48f3409f-8199-44a7-8070-81cda64c3d20', code: 'live_here', name: 'Живут в указанном регионе' };
			// this.model.scopes = [];
			this.model.regions = [];
			// this.model.tags = [];
			this.model.countries = [];
			this.model.work_permissions = [];
			this.model.driving_categories = [];
			// this.model.institutions = [];
			this.model.languages = [];
			this.model.experience_types = [];
			this.model.sex = { id: 'afdbfbc2-9bae-4597-8129-5adf5aa18333', code: 'Любой', name: 'Любой', short_name: 'Любой', state: '', ts_create: null };
			this.model.required = [];
			this.model.bought_resumes = true;
			this.model.operators_search = true;
			this.model.hide_viewed_resumes = false;
			this.model.sort_order = 'change_date';
			this.model.start_salary = null;
			this.model.end_salary = null;
			this.model.start_age = null;
			this.model.end_age = null;
			this.model.only_with_age = false;
			this.model.only_with_salary = false;
			this.model.employment_types = [];
			this.model.schedule_types = [];
			this.model.has_auto = false;
		},
		customFilter(item, queryText, itemText) {
			return itemText.toLowerCase().indexOf(queryText.toLowerCase().replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '')) >= 0;
		},
		sortGuide(guid) {
			return guid 
				? guid.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
				: [];
		},
		removeRegion(list, item) {
			let index = list.findIndex(r => r.code === item.code);
			if (index >= 0) list.splice(index, 1);
			let idx = this.allRegions.findIndex(r => r.code === item.code);
			if (idx >= 0) this.allRegions.splice(idx, 1);
		},
		openVacancy(item) {
			window.open(`/#/vacancies/list/${item.id}`, '_blank');
		}
	}
}
</script>

<style lang="less">
.simple-search {
	margin: 0px 8px;
	padding-left: 0px;

	.v-chip {
		margin-left: 0;
		height: 36px !important;
	}
	
	.v-btn-toggle{
		.v-btn {
			box-shadow: none !important;
			border-color: #a6a6a6;
			border-width: 1px;
			border-style: solid;
			color:black;
			opacity: unset;
		}
	}
	> .layout {
		padding-bottom: 4px;
	}
	>.flex {
		margin-bottom: 6px;
	}
	.flex.xs3 {
		min-width: 50px;
	}
	.checkbox-group {
		margin-top: 2px;
		margin-left: -2px;
		.v-input__slot {
			margin-bottom: 4px;
		}
		.v-input--selection-controls {
			margin-top: 0px;
			padding-top: 0px;
		}
	}
	.v-input--radio-group {
		margin-top: 0px;
		padding-top: 0px;
		margin-left: -2px;
		.v-messages {
			display: none;
		}
	}
}
</style>