<template>
  <v-layout>
    <v-flex xs4></v-flex>
    <v-flex xs>
      <h1>
        {{ message }}
      </h1>

      <v-btn v-if="resume_url" class="primary ml-0" :href="resume_url">
        Открыть предыдущую версию
      </v-btn>

      <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-flex>
  </v-layout>
</template>

<script>
const CONNECT       = 'connect';
const READY         = 'ready';
const RESUME_OPEN   = 'resume:open';
const TYPE          = 'type';
const TYPE_USER     = 'user';

export default {
  name: 'app-loading',

  data() {
    return {
      loading : true,
      message : '',
      resume_url: null
    }
  },

  sockets: {
    events: {
      [CONNECT]() {
        this.$sockets.$emit(TYPE, TYPE_USER);
      },
      [READY]() {
        if (this.$route.params.id) {
          this.message = 'Обновление резюме...';
        } else {
          this.message = 'Загрузка...';
        }

        this.$sockets.$emit(RESUME_OPEN, {
          task_id         : this.$core.uuid(),
          sid             : this.$route.params.sid,
          organization_id : window.localStorage['organization']
        });
      },
      [RESUME_OPEN]({ task_id, data, error }) {
        if (error || !data || !data.id) {
          this.loading = false;
          this.message = error || 'Что-то пошло не так, обратитесь к вашему менеджеру';
          if (this.$route.params.id) {
            this.resume_url = `${location.origin}/#/resume/${this.$route.params.id}`;
          }
        } else {
          if (data.hiddens) {
            console.log('data.hiddens', data.hiddens.fio);
            console.log('data.hiddens', data.hiddens.phone);
            console.log('data.hiddens', data.hiddens.email);
          }
          window.location.replace(`${location.origin}/#/resume/${data.id}`);
        }
      }
    }
  },

  methods: {
    copy() {
      this.$core.tools.copyToClipboard(location.href);
    }
  },

  mounted() {
    this.$context.subTitle = 'Просмотр резюме';
  }
}
</script>

