<template>
	<v-container class="extended-search">
		<v-layout row wrap>
			<v-flex offset-xs2 xs8 >
				<v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="$emit('update:simple', true)">Простой поиск</v-btn>
				<v-btn class="v-btn--text-transform-none ml-0" color="primary" style="cursor: default" :ripple="false" depressed :input-value="true">Расширенный поиск</v-btn>
				<a @click="clear()"><u>Очистить</u></a>
			</v-flex>
		</v-layout>
		<v-layout row wrap  v-if="searchForVacancy">
			<v-flex xs2>
				<h3>Для вакансии: </h3>
			</v-flex>
			<v-flex xs8>
				<v-chip
					v-model="searchForVacancy"
					close
					label
					outline
					@click="openVacancy(vacancy)"
				>   
					<span style="cursor: pointer">
						{{ vacancy.name }}
					</span>
				</v-chip>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2 >
				<h3>Ключевые слова: </h3>
			</v-flex>
			<v-flex xs8 >
				<v-container fluid grid-list-md class="pa-0">
					<app-search-panel
						v-for="(child, index) in model.search_settings"
						:key="index"
						:model="child"
						:show-close="index > 0"
						@remove="removeSearchPanel"
						v-if="showSearchPanel"
					/>
					<v-layout row wrap align-center>
						<v-flex shrink>
							<v-btn @click="addSearchPanel()" class="ma-0 add-filter">Добавить фильтр</v-btn>
						</v-flex>
					</v-layout>
				</v-container>
			</v-flex>
		</v-layout>
		<!-- <v-layout row wrap>
				<v-flex xs2>
					<h3>Профессиональная область: </h3>
				</v-flex>
				<v-flex xs8>
					<v-autocomplete 
						solo
						placeholder="Любая"
						v-model="model.scopes"
						:items="scopes"
						:search-input.sync="searchScopes"
						:filter="customFilter"
						return-object
						multiple
						no-data-text="Начните вводить название профессиональной области"
						item-text="name"
						@change="searchScopes = null"
					>
						<template slot="selection" slot-scope="data">
							<v-chip 
								:selected="data.selected"
								close
								@input="removeElement(model.scopes, data.item)"
							>
								{{ data.item.name }}
							</v-chip>
						</template>
					</v-autocomplete>
				</v-flex>
		</v-layout> -->
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Регион: </h3>
			</v-flex>
			<v-flex xs8>
				<v-autocomplete 
					solo
					placeholder="Укажите регион"
					v-model="model.regions"
					:items="regions"
					:search-input.sync="searchRegions"
					:filter="customFilter"
					return-object
					multiple
					no-data-text="Начните вводить название региона"
					item-text="name"
					@change="searchRegions = null">
					<template slot="selection" slot-scope="data">
						<v-chip 
							:selected="data.selected"
							close
							@input="removeRegion(model.regions, data.item)"
						>
							{{ data.item.name }}
						</v-chip>
					</template>
				</v-autocomplete>
				<v-select 
					solo
					v-model="model.move_type"
					:items="move_types"
					:menu-props="{ offsetY : true }"
					return-object
					item-text="name"
				/>
			</v-flex>
		</v-layout>
		<v-layout row wrap v-if="false && model.regions && model.regions.length">
			<v-flex xs2>
				<h3>Метро: </h3>
			</v-flex>
			<v-flex xs8>
				<v-autocomplete 
					solo
					placeholder="Укажите метро"
					v-model="model.metro"
					:items="subways"
					:search-input.sync="searchMetro"
					:filter="customFilter"
					return-object
					multiple
					no-data-text="Начните вводить название метро"
					item-text="name"
					@change="searchMetro = null"
				>
					<template slot="selection" slot-scope="data">
						<v-chip 
							:selected="data.selected"
							close
							@input="removeMetro(model.metro, data.item)"
						>
							{{ data.item.name }}
						</v-chip>
					</template>
				</v-autocomplete>
				<v-select 
					solo
					v-model="model.move_type"
					:items="move_types"
					:menu-props="{ offsetY : true }"
					return-object
					item-text="name"
				/>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Зарплата: </h3>
			</v-flex>
			<v-flex xs8 v-if="showSalaryPanel">
				<app-salary-panel :model="model" :currencies="currencies"/>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2 class="mt-1">
				<h3>Требуемый опыт</h3>
				<h3>работы: </h3>
			</v-flex>
			<v-flex xs8 class="checkbox-group mt-3">
				<v-checkbox 
					v-for="exp_type in experience_types" 
					:key="exp_type.id"
					v-model="model.experience_types"
					:value="exp_type.id" 
					:label="`${exp_type.name}`" 
					style="margin-top: -10px"
				/>
			</v-flex>
		</v-layout>
		<!--<v-layout row wrap>
					<v-flex xs2>
						<h3>Ключевые навыки: </h3>
					</v-flex>
					<v-flex xs8>
						<v-autocomplete 
							solo
							placeholder="Укажите навык"
							v-model="model.tags"
							:items="tags"
							:search-input.sync="searchTags"
							return-object
							multiple
							item-text="name"
						>
							<template slot="selection" slot-scope="data">
								<v-chip 
									:selected="data.selected"
									close
									@input="removeElement(model.tags, data.item)"
								>
									{{ data.item.name }}
								</v-chip>
							</template>
						</v-autocomplete>
					</v-flex>
		</v-layout>-->
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Гражданство: </h3>
			</v-flex>
			<v-flex xs8>
				<v-autocomplete 
					solo
					placeholder="Укажите страну"
					v-model="model.countries"
					:items="countries"
					:search-input.sync="searchCitizenship"
					:filter="customFilter"
					return-object
					multiple
					no-data-text="Начните вводить название страны"
					item-text="name"
					@change="searchCitizenship = null"
				>
					<template slot="selection" slot-scope="data">
						<v-chip 
							:selected="data.selected"
							close
							@input="removeElement(model.countries, data.item)"
						>
							{{ data.item.name }}
						</v-chip>
					</template>
				</v-autocomplete>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Разрешение на работу: </h3>
			</v-flex>
			<v-flex xs8>
				<v-autocomplete solo
					placeholder="Укажите страну"
					v-model="model.work_permissions"
					:items="work_permissions"
					:search-input.sync="searchPermits"
					:filter="customFilter"
					return-object
					multiple
					no-data-text="Начните вводить название страны"
					item-text="name"
					@change="searchPermits = null"
				>
					<template slot="selection" slot-scope="data">
						<v-chip 
							:selected="data.selected"
							close
							@input="removeElement(model.work_permissions, data.item)"
						>
							{{ data.item.name }}
						</v-chip>
					</template>
				</v-autocomplete>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Возраст: </h3>
			</v-flex>
			<v-flex xs8>
				<app-age-panel :model="model" v-if="showAgePanel" />
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Пол: </h3>
			</v-flex>
			<v-flex shrink>
				<v-select 
					style="max-width: 150px"
					solo
					v-model="model.sex"
					:items="sexes"
					:menu-props="{ offsetY : true }"
					return-object
					item-text="name"
				/>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Тип занятости: </h3>
			</v-flex>
			<v-flex xs8>
				<v-autocomplete 
					solo
					placeholder="Выберите тип занятости"
					v-model="model.employment_types"
					:items="employment_types"
					:search-input.sync="searchEmplTypes"
					:menu-props="{ offsetY : true }"
					return-object
					multiple
					item-text="name"
					chips
				>
					<template slot="selection" slot-scope="data">
						<v-chip 
							:selected="data.selected"
							close
							@input="removeElement(model.employment_types, data.item)"
						>
							{{ data.item.name }}
						</v-chip>
					</template>
				</v-autocomplete>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>График работы: </h3>
			</v-flex>
			<v-flex xs8>
				<v-autocomplete solo
					placeholder="Выберите график работы"
					v-model="model.schedule_types"
					:items="schedule_types"
					:search-input.sync="searchSchedTypes"
					:menu-props="{ offsetY : true }"
					return-object
					multiple
					item-text="name"
					chips
				>
					<template slot="selection" slot-scope="data">
						<v-chip 
							:selected="data.selected"
							close
							@input="removeElement(model.schedule_types, data.item)"
						>
							{{ data.item.name }}
							</v-chip>
					</template>
				</v-autocomplete>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Автомобиль: </h3>
			</v-flex>
			<v-flex xs8 class="checkbox-group">
				<v-checkbox 
					v-model="model.has_auto" 
					label="Есть личный автомобиль"
				/>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Категория прав: </h3>
			</v-flex>
			<v-flex xs8 style="margin-bottom: 16px">
				<v-btn-toggle v-model="model.driving_categories" multiple>
					<v-btn
						v-for="cat in driving_categories"
						:key="cat.id"
						:value="cat.id"
						style="width:36px; height:36px; color:black;"
						flat
					>
						{{ cat.name }}
					</v-btn>
				</v-btn-toggle>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Знание языков: </h3>
			</v-flex>
			<v-flex xs8>
				<v-layout column v-for="(lang, index) in model.languages" :key="index">
					<app-language-panel
						:model="lang"
						:language_levels="language_levels"
						:languages="languages"
						@remove="removeLanguage(lang)"
					/>
				</v-layout>
				<v-layout>
					<a 
						href="javascript:void(0)" 
						v-on:click="addLanguage()" 
						style="margin-bottom: 4px"
					>
						Добавить язык
					</a>
				</v-layout>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Не показывать резюме без следующих данных: </h3>
			</v-flex>
			<v-flex xs8 class="checkbox-group mt-3">
				<v-checkbox 
					v-for="req in required" 
					:key="req.id" 
					v-model="model.required"
					:value="req" 
					:label="`${req.name}`" 
					style="margin-top: -10px"
				/>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Сортировать: </h3>
			</v-flex>
			<v-flex xs8>
				<v-radio-group v-model="model.sort_order">
					<v-radio
						v-for="order in sort_orders"
						:key="order.id"
						:label="`${order.name}`"
						:value="order.id"
					/>
				</v-radio-group>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex xs2>
				<h3>Выводить: </h3>
			</v-flex>
			<v-flex xs8>
				<v-radio-group v-model="model.search_period">
					<v-radio 
						dense
						v-for="period in search_periods"
						:key="period.id"
						:label="`${period.name}`"
						:value="period"
					/>
				</v-radio-group>
				<v-flex v-if="model.search_period.code === 'interval'">
					<v-menu
						v-model="menu1"
						:close-on-content-click="false"
						lazy
						transition="scale-transition"
						offset-y
						class="d-inline-flex"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								v-model="model.ts_from"
								label="Период с"
								readonly
								v-on="on"
								class="d-inline-flex ma-1"
								style="width: 200px"
							/>
						</template>
						<v-date-picker
							v-model="model.ts_from"
							@input="menu1 = false"
							locale="ru-ru"
						/>
					</v-menu>

					<v-menu
						v-model="menu2"
						:close-on-content-click="false"
						lazy
						transition="scale-transition"
						offset-y
						class="d-inline-flex"
					>
						<template v-slot:activator="{ on }">
							<v-text-field
								v-model="model.ts_before"
								label="Период по"
								readonly
								v-on="on"
								class="d-inline-flex ma-1"
								style="width: 200px"
							/>
						</template>
						<v-date-picker
							v-model="model.ts_before"
							@input="menu2 = false"
							locale="ru-ru"
						/>
					</v-menu>
				</v-flex>
			</v-flex>
		</v-layout>
		<v-layout row wrap>
			<v-flex offset-xs2 xs8 >
				<v-checkbox 
					style="display: inline-flex"
					v-model="model.bought_resumes" 
					label="Искать среди купленных резюме"
				/>
				<v-checkbox 
					style="display: inline-flex" 
					class="ml-1"
					v-model="model.operators_search" 
					label="Моментальный поиск операторами"
				/>
			</v-flex>
			<v-flex offset-xs2 xs8 >
				<v-checkbox 
					style="display: inline-flex"
					v-model="model.hide_viewed_resumes" 
					label="Скрыть уже просмотренные"
				/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import AppLanguagePanel from './language-panel.vue';
import AppSalaryPanel from './salary-panel.vue';
import AppAgePanel from './age-panel.vue';
import AppSearchPanel from './search-panel.vue';

export default {
	name: 'app-extended-search',

	components: {
		AppAgePanel, 
		AppSalaryPanel, 
		AppLanguagePanel, 
		AppSearchPanel
	},

	props: {
		simple: {
			type: Boolean
		},
		model: {
			type: Object
		}
	},

	data() {
		return {
			// allScopes: [],
			allRegions: [],
			allMetro: [],
			// allTags: [],
			allCountries: [],
			allPermits: [],
			allSexes: [],
			allCurrencies: [],
			allLanguages: [],
			allLanguageLevels: [],
			allEmploymentTypes: [],
			allScheduleTypes: [],
			// allEducationLevels: [],
			sort_orders: this.$consts.sort_orders,
			required: this.$consts.required,
			// searchScopes: null,
			searchRegions: null,
			searchMetro: null,
			// searchTags: null,
			searchCitizenship: null,
			searchPermits: null,
			searchEmplTypes: null,
			searchSchedTypes: null,
			showAgePanel: true,
			showSalaryPanel: true,
			showSearchPanel: true,
			menu1: null,
			menu2: null,
			ts_from: null,
			ts_before: null,
			vacancy: {},
			searchForVacancy: false
		}
	},

	remoteData: {
		allSexes : '/api/v1/main/guides/sex/short',
		// allTags   : '/api/v1/main/guides/tags/short',
		allCurrencies : '/api/v1/main/guides/currencies/short',
		// allLanguages   : '/api/v1/main/guides/languages/short',
		// allLanguageLevels : '/api/v1/main/guides/language_levels/short',
		allDrivingCategories : '/api/v1/main/guides/driving_categories/short',
		// allEducationLevels: '/api/v1/main/guides/education_levels/short',
		allEmploymentTypes: '/api/v1/main/guides/employment_types/short',
		allScheduleTypes: '/api/v1/main/guides/schedule_types/short',
		allMoveTypes: '/api/v1/main/guides/move_types/short',
		allSearchPeriods: '/api/v1/main/guides/search_periods/short',
		allExperienceTypes: '/api/v1/main/guides/experience_types/short',
	},

	computed: {
		// scopes() {
		// 	return this.sortGuide(this.allScopes);
		// },
		regions() {
			if (this.model.regions && this.model.regions.length) this.allRegions.push(...this.model.regions);
			return this.sortGuide(this.allRegions);
		},
		subways() {
			if (this.model.metro && this.model.metro.length) this.allMetro.push(...this.model.metro);
			return this.sortGuide(this.allMetro);
		},
		countries() {
			if (this.model.countries && this.model.countries.length) this.allCountries.push(...this.model.countries);
			return this.sortGuide(this.allCountries);
		},
		work_permissions() {
			if (this.model.work_permissions && this.model.work_permissions.length) this.allPermits.push(...this.model.work_permissions);
			return this.sortGuide(this.allPermits);
		},
		move_types() {
			return this.allMoveTypes || [];
		},
		search_periods() {
			return this.allSearchPeriods || [];
		},
		experience_types() {
			return this.allExperienceTypes || [];
		},
		// tags() {
		//   return this.sortGuide(this.allTags);
		// },
		sexes() {
			return this.sortGuide(this.allSexes);
		},
		languages() {
			return this.sortGuide(this.allLanguages).filter(i => i.state === 'confirmed');
		},
		language_levels() {
			return this.sortGuide(this.allLanguageLevels).filter(i => i.state === 'confirmed');
		},
		currencies() {
			return this.sortGuide(this.allCurrencies).filter(i => i.state === 'confirmed');
		},
		// education_levels() {
		// 	return this.sortGuide(this.allEducationLevels);
		// },
		employment_types() {
			return this.sortGuide(this.allEmploymentTypes).filter(i => i.state === 'confirmed');
		},
		schedule_types() {
			return this.sortGuide(this.allScheduleTypes).filter(i => i.state === 'confirmed');
		},
		driving_categories() {
			return this.sortGuide(this.allDrivingCategories).filter(i => i.state === 'confirmed');
		}
	},

	watch: {
		'model.search_period': {
			handler(newValue, oldValue) {
				if (newValue.code === 'interval' && !this.model.ts_from && !this.model.ts_before) {
					let now = new Date();
					this.model.ts_from = now.getFullYear() + '-' + this.addFirstZero((now.getMonth() + 1)) + '-' + this.addFirstZero(now.getDate());
					this.model.ts_before = now.getFullYear() + '-' + this.addFirstZero((now.getMonth() + 1)) + '-' + this.addFirstZero(now.getDate());
				} else if (newValue.code !== 'interval') {
					this.model.ts_from = null;
					this.model.ts_before = null;
				}
			}
		},
		searchScopes() {
			if (this.isLoading) return;
			this.isLoading = true;

			fetch('/api/v1/main/guides/scopes/short_query/' + (this.searchScopes && this.searchScopes.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query'))
				.then(res => res.json())
				.then(res => {
					this.allScopes = res;
					if (this.model.scopes && this.model.scopes.length) this.allScopes.push(...this.model.scopes);
				})
				.catch(err => {
					console.log('err', err)
				})
				.finally(() => (this.isLoading = false))
		},
		searchRegions() {
			if (this.isLoading) return;
			this.isLoading = true;

			fetch('/api/v1/main/guides/regions/short_query/' + (this.searchRegions && this.searchRegions.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query'))
				.then(res => res.json())
				.then(res => {
					this.allRegions = res.filter(i => i.type === 'hh');
					if (this.model.regions && this.model.regions.length) this.allRegions.push(...this.model.regions);
				})
				.catch(err => {
					console.log('err', err)
				})
				.finally(() => (this.isLoading = false))
		},
		searchMetro() {
			if (this.isLoading) return;
			this.isLoading = true;

			fetch('/api/v1/main/guides/metro/short_query/' + (this.searchMetro && this.searchMetro.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query'))
				.then(res => res.json())
				.then(res => {
					this.allMetro = res;
					if (this.model.metro && this.model.metro.length) this.allMetro.push(...this.model.metro);
				})
				.catch(err => {
					console.log('err', err)
				})
				.finally(() => (this.isLoading = false))
		},
		// searchTags() {
		// 	if (this.tagsTimer) clearTimeout(this.tagsTimer);
		// 	this.tagsTimer = setTimeout(async () => {
		// 		await this.getTags();
		// 		this.tagsTimer = null;
		// 	}, this.$consts.timeout)
		// },
		searchCitizenship() {
			if (this.isLoading) return;
			this.isLoading = true;

			fetch('/api/v1/main/guides/countries/short_query/' + (this.searchCitizenship && this.searchCitizenship.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query'))
				.then(res => res.json())
				.then(res => {
					this.allCountries = res;
					if (this.model.countries && this.model.countries.length) this.allCountries.push(...this.model.countries);
				})
				.catch(err => {
					console.log('err', err)
				})
				.finally(() => (this.isLoading = false))
		},
		searchPermits() {
			if (this.isLoading) return;
			this.isLoading = true;

			fetch('/api/v1/main/guides/countries/short_query/' + (this.searchPermits && this.searchPermits.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query'))
				.then(res => res.json())
				.then(res => {
					this.allPermits = res;
					if (this.model.work_permissions && this.model.work_permissions.length) this.allPermits.push(...this.model.work_permissions);
				})
				.catch(err => {
					console.log('err', err)
				})
				.finally(() => (this.isLoading = false))
		},
		searchEmplTypes() {
			if (this.emplTypesTimer) clearTimeout(this.emplTypesTimer);
			this.emplTypesTimer = setTimeout(async () => {
				await this.getEmplTypes();
				this.emplTypesTimer = null;
			}, this.$consts.timeout)
		},
		searchSchedTypes() {
			if (this.schedTypesTimer) clearTimeout(this.schedTypesTimer);
			this.schedTypesTimer = setTimeout(async () => {
				await this.getSchedTypes();
				this.schedTypesTimer = null;
			}, this.$consts.timeout)
		},
		searchForVacancy() {
			if (!this.searchForVacancy) {
				this.vacancy = {};
				window.localStorage.removeItem('vacancy_id');
				this.model.vacancy_id = null;
			}
		},
		// 'model.tags': {
		// 	handler(val, prev) {
		// 		if (!prev || val.length === prev.length) return;
		// 		this.model.tags = val.map(v => {
		// 			if (typeof v === 'string') {
		// 					v = {
		// 							id: -1,
		// 							name: v
		// 					};
		// 			};
		// 			return v;
		// 		})
		// 	},
		// 	immediate: true
		// }
	},

	async mounted() {
		if (this.$context.user.isResearcher || this.$context.user.isAdmin) {
			if (this.model.vacancy_id) {
				this.vacancy = (await this.$http.get(`/api/v1/recruiting/vacancy/${this.model.vacancy_id}`)).data;
				this.searchForVacancy = true;
			}
		} else {
			this.searchForVacancy = false;
		}

		let res = (await this.$http.get('/api/v1/main/searching/search/rur')).data;
		if (res) {
			this.model.currency = res;
		}

		await this.getLanguages();
		await this.getLanguageLevels();
	},


	methods: {
		addFirstZero(item) {
			if (('' + item).length === 1) return '0' + item;
			return item;
		},
		async clear() {
			this.showAgePanel = false;
			this.showSalaryPanel = false;
			this.showSearchPanel = false;

			this.model.search_settings = [{
				text: '',
				search_type: { id: '0efa102f-03ff-4a1d-aa94-348a258259e3', code: 'all_words', name: 'Все слова' },
        experience_period: { id: '5198423c-fe7a-490a-96b9-3201ac57a8a0', code: 'all', name: 'За все время опыта' },
				search_areas: []
			}];
			// this.model.birth_day = null;
			// this.model.birth_month = null;
			// this.model.birth_year = null;
			this.model.search_period = { id: '4892c204-611a-49de-88c3-4962dcd8fbb5', code: 'all_time', name: 'За весь период' };
			this.model.move_type = { id: '7db94f23-62ab-4ae5-baf4-1ab603ae7957', code: 'live_or_move_here', name: 'Живут в указанном регионе или готовы переехать в него' };
			//this.model.scopes = [];
			this.model.regions = [];
			this.model.metro = [];
			// this.model.tags = [];
			this.model.countries = [];
			this.model.work_permissions = [];
			this.model.driving_categories = [];
			// this.model.institutions = [];
			this.model.languages = [];
			this.model.experience_types = [];
			this.model.sex = { id: 'afdbfbc2-9bae-4597-8129-5adf5aa18333', code: 'Любой', name: 'Любой', short_name: 'Любой', state: '', ts_create: null };
			this.model.required = [];
			this.model.bought_resumes = true;
			this.model.operators_search = true;
			this.model.hide_viewed_resumes = false;
			this.model.sort_order = 'change_date';
			this.model.start_salary = null;
			this.model.end_salary = null;
			this.model.start_age = null;
			this.model.end_age = null;
			this.model.only_with_age = false;
			this.model.only_with_salary = false;
			this.model.employment_types = [];
			this.model.schedule_types = [];
			this.model.has_auto = false;

			let res = (await this.$http.get('/api/v1/main/searching/search/rur')).data;
			if (res) {
				this.model.currency = res;
			}

			this.$nextTick(()=>{
				this.showAgePanel = true;
				this.showSalaryPanel = true;
				this.showSearchPanel = true;
			});
		},
		customFilter(item, queryText, itemText) {
			return itemText.toLowerCase().indexOf(queryText.toLowerCase().replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '')) >= 0;
		},
		addSearchPanel() {
			this.model.search_settings.push({
				text: '',
				search_type: { id: '0efa102f-03ff-4a1d-aa94-348a258259e3', code: 'all_words', name: 'Все слова' },
        experience_period: { id: '5198423c-fe7a-490a-96b9-3201ac57a8a0', code: 'all', name: 'За все время опыта' },
				search_areas: []
			});
		},
		removeSearchPanel(model) {
			let index = this.model.search_settings.indexOf(model);
			if (index >= 0) this.model.search_settings.splice(index, 1);
		},
		removeElement(list, item) {
			let index = list.findIndex(r => r.code === item.code);
			if (index >= 0) list.splice(index, 1);
		},
		removeRegion(list, item) {
			let index = list.findIndex(r => r.code === item.code);
			if (index >= 0) list.splice(index, 1);
			let idx = this.allRegions.findIndex(r => r.code === item.code);
			if (idx >= 0) this.allRegions.splice(idx, 1);
		},
		removeMetro(list, item) {
			let index = list.findIndex(r => r.code === item.code);
			if (index >= 0) list.splice(index, 1);
			let idx = this.allMetro.findIndex(r => r.code === item.code);
			if (idx >= 0) this.allMetro.splice(idx, 1);
		},
		async addLanguage() {
			this.model.languages.push({
				language: {},
				language_level: {}
			});
		},
		removeLanguage(language) {
			let index = this.model.languages.indexOf(language);
			if (index >= 0) this.model.languages.splice(index, 1);
		},
		sortGuide(guid) {
			return guid 
				? guid.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
				: [];
		},
		async getLanguages() {
			this.allLanguages = (await this.$http.get('/api/v1/main/guides/languages/short')).data;
		},
		async getLanguageLevels() {
			this.allLanguageLevels = (await this.$http.get('/api/v1/main/guides/language_levels/short')).data;
		},
		// async getTags(){
		//   this.allTags = (await this.$http.get('/api/v1/main/guides/tags/short')).data;
		// },
		async getEmplTypes() {
			this.allEmploymentTypes = (await this.$http.get('/api/v1/main/guides/employment_types/short')).data;
		},
		async getSchedTypes() {
			this.allScheduleTypes = (await this.$http.get('/api/v1/main/guides/schedule_types/short')).data;
		},
		openVacancy(item) {
			window.open(`/#/vacancies/list/${item.id}`, '_blank');
		}
	}
}
</script>

<style lang="less">
.extended-search {
	margin: 0px 8px;
	padding-left: 0px;

	.v-chip {
		margin-left: 0;
		height: 36px !important;
	}

	.v-btn-toggle{
		.v-btn {
			box-shadow: none !important;
			border-color: #a6a6a6;
			border-width: 1px;
			border-style: solid;
			color:black;
			opacity: unset;
		}
	}
	> .layout {
		padding-bottom: 4px;
	}
	>.flex {
		margin-bottom: 6px;
	}
	.flex.xs3 {
		min-width: 50px;
	}
	.checkbox-group {
		margin-top: 2px;
		margin-left: -2px;
		.v-input__slot {
			margin-bottom: 4px;
		}
		.v-input--selection-controls {
			margin-top: 0px;
			padding-top: 0px;
		}
	}
	.v-input--radio-group {
		margin-top: 0px;
		padding-top: 0px;
		margin-left: -2px;
		.v-messages {
			display: none;
		}
	}
}
</style>